import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '../icon/icon.module';
import { KurzTooltipModule } from '../../directives/kurz-tooltip/kurz-tooltip.module';
import { ActivateElementModule } from '../../directives/activate-element/activate-tab-element.module';
import { ColorThemeModule } from '../shared/color-theme/color-theme.module';
import { InputDateRenderingComponent, InputDefaultRenderingComponent } from './input-default-rendering.component';


@NgModule({
  declarations: [
    InputComponent,
    InputDefaultRenderingComponent,
    InputDateRenderingComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconModule,
    KurzTooltipModule,
    ActivateElementModule,
    ColorThemeModule
  ],
  exports: [
    InputComponent,
    ColorThemeModule,
  ]
})
export class InputModule { }
